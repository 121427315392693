import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { RootState } from "../reducers";
import GenericBeppoModal from "./general/genericBeppoModal";

interface EvidencePhotosModalProps {
  onClose: () => void;
  open: boolean;
  title: string;
}

const EvidencePhotosModal = (props: EvidencePhotosModalProps) => {
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const [images] = useState(ticket.imageUrls);

  const mapImagesToPhotos = () => {
    let photos: { original: string; thumbnail: string }[] = [];
    images.map((image) => {
      const photo = {
        original: image,
        thumbnail: image,
      };
      return photos.push(photo);
    });

    return photos;
  };

  return (
    <GenericBeppoModal
      title={props.title}
      open={props.open}
      onClose={props.onClose}
    >
      <ImageGallery items={mapImagesToPhotos()} showPlayButton={false} />
    </GenericBeppoModal>
  );
};

export default EvidencePhotosModal;
