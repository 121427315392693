import React from "react";
import Style from "./entityDetail.module.scss";

const EntityDetail = (props: any) => {
  return (
    <div className={Style.formMobile__paragraph}>
      <span className={Style.formMobile__title}>{props.title}</span>
      <div className={Style.horizontalLine}></div>
      <span className={Style.formMobile__paragraphBold}>{props.value}</span>
    </div>
  );
};

export default EntityDetail;
