import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/button/button";
import LoadingIndicator from "../reusable/loadingIndicator";
import Style from "./objectionMobile.module.scss";
import { useTranslation } from "react-i18next";
import LabelWithHR from "../common/label/labelWithHR";
import { Objection } from "../reducers/ticket";
import { RootState } from "../reducers";
import { createObjection } from "../actions/ticket";
import { useHistory } from "react-router-dom";
import GenericMobileCard from "./general/genericMobileCard";

const ObjectionMobile = () => {
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const { isLoading } = useSelector((state: RootState) => state.loader);

  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setError] = useState("");
  let history = useHistory();

  const [objection, setObjection] = useState<Objection>({
    firstName: "",
    lastName: "",
    email: "",
    complaintText: "",
    parkingZoneId: ticket.parkingZone?.id,
    gasStationZoneId: ticket.gasStationZone?.id,
    ticketId: ticket.id,
  });

  const sendObjection = async () => {
    dispatch(createObjection(objection, setError, history));
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }
  return (
    <GenericMobileCard title={t("Objection.Objection").toString()}>
      <form onSubmit={handleSubmit(sendObjection)}>
        <div>
          <LabelWithHR label={t("Objection.E-mail")} />
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"text"}
                className={Style.input}
                name="email"
                onChange={(e) => {
                  setObjection({ ...objection, email: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div className={Style.form__flex}>
          <div className={Style.form__flex__margin}>
            <LabelWithHR label={t("Objection.First name")} />
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="firstName"
                  onChange={(e) => {
                    setObjection({
                      ...objection,
                      firstName: e.target.value,
                    });
                  }}
                />
              )}
            />
          </div>
          <div>
            <LabelWithHR label={t("Objection.Last name")} />
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="lastName"
                  onChange={(e) => {
                    setObjection({
                      ...objection,
                      lastName: e.target.value,
                    });
                  }}
                />
              )}
            />
          </div>
        </div>
        <LabelWithHR label={t("Objection.Message")} />
        <Controller
          name="complaintText"
          defaultValue=""
          control={control}
          render={() => (
            <textarea
              className={Style.textarea}
              name="complaintText"
              onChange={(e) => {
                setObjection({
                  ...objection,
                  complaintText: e.target.value,
                });
              }}
            />
          )}
        />
        <Button
          text={t("Objection.Send message")}
          disabled={
            !objection.firstName ||
            !objection.lastName ||
            !objection.email ||
            !objection.complaintText
          }
          classNameWrapper={Style.buttonMobile}
        />
      </form>
    </GenericMobileCard>
  );
};

export default ObjectionMobile;
