import React from "react";
import Style from "./genericBeppoModal.module.scss";
import Modal from "react-responsive-modal";

interface GenericBeppoModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title: string;
}

const GenericBeppoModal = (props: GenericBeppoModalProps) => {
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      classNames={{
        modal: `${Style.modalStyle}`,
        closeButton: `${Style.modalStyleCloseButton}`,
        closeIcon: `${Style.modalStyleCloseIcon}`,
      }}
    >
      <div className={Style.headerLine} />
      <div className={Style.modalTitle}>{props.title}</div>
      <div className={Style.mainContainer}>
        <div className={Style.form}>{props.children}</div>
      </div>
    </Modal>
  );
};

export default GenericBeppoModal;
