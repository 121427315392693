import React, { useState } from "react";
import { useSelector } from "react-redux";
import Style from "./evidencePhotos.module.scss";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { RootState } from "../reducers";
import GenericMobileCard from "./general/genericMobileCard";

const EvidencePhotos = () => {
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const [images] = useState(ticket.imageUrls);
  const { t } = useTranslation();

  const mapImagesToPhotos = () => {
    let photos: { original: string; thumbnail: string }[] = [];
    images.map((image) => {
      const photo = {
        original: image,
        thumbnail: image,
      };
      return photos.push(photo);
    });

    return photos;
  };

  return (
    <GenericMobileCard title={t("Evidence.photos").toString()}>
      <div className={Style.formMobile__imgContainer}>
        <ImageGallery
          items={mapImagesToPhotos()}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </div>
    </GenericMobileCard>
  );
};

export default EvidencePhotos;
