import React from "react";
import { isMobile } from "react-device-detect";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import GenericBeppoModal from "./general/genericBeppoModal";
import Style from "./infoModal.module.scss";

interface InfoModalProps {
  title: string;
  infoText: string;
  open: boolean;
  onClose: any;
}

const InfoModal = (props: InfoModalProps) => {
  const renderContent = () => {
    if (isMobile) {
      return (
        <Modal
          open={props.open}
          onClose={props.onClose}
          center
          closeOnOverlayClick={false}
          showCloseIcon={false}
          classNames={{
            modal: `${Style.modalStyleMobile}`,
            closeButton: `${Style.modalStyleCloseButton}`,
            closeIcon: `${Style.modalStyleCloseIcon}`,
          }}
        >
          <div className={Style.headerLine} />
          <div className={Style.modalTitle}>{props.title}</div>
          <div className={Style.infoModalText}>{props.infoText}</div>
        </Modal>
      );
    }
    return (
      <GenericBeppoModal
        title={props.title}
        open={props.open}
        onClose={props.onClose}
      >
        <div className={Style.textStyle}>{props.infoText}</div>
      </GenericBeppoModal>
    );
  };

  return renderContent();
};

export default InfoModal;
