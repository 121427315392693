import React from "react";
import Style from "./button.module.scss";
import { isMobile } from "react-device-detect";

interface ButtonProps {
  desktop: boolean;
  style?: any;
  disabled: boolean;
  buttonStyle?: any;
  onClick?: () => void;
  text: string;
  classNameWrapper?: any;
}

const Button = (props: ButtonProps) => {
  const getClassName = (disabled: boolean, isMobile: boolean) => {
    if (disabled && isMobile) {
      return Style.btnSubmitMobileDisabled;
    }
    if (!disabled && isMobile) {
      return Style.btnSubmitMobile;
    }
    if (!disabled && !isMobile) {
      return Style.btnSubmitDesktop;
    }
    if (disabled && !isMobile) {
      return Style.btnSubmitDesktopDisabled;
    }
  };

  return (
    <div className={props.classNameWrapper}>
      <button
        type="submit"
        className={getClassName(props.disabled, isMobile)}
        disabled={props.disabled}
        style={props.buttonStyle}
        onClick={props.onClick}
      >
        {props.text}
      </button>
    </div>
  );
};

Button.defaultProps = {
  desktop: true,
  disabled: false,
};

export default Button;
