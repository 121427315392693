import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/button/button";
import Style from "./orderMobile.module.scss";
import { useTranslation } from "react-i18next";
import InfoModal from "./infoModal";
import LoadingIndicator from "../reusable/loadingIndicator";
import { RootState } from "../reducers";
import { OrderBill } from "../reducers/ticket";
import LabelWithHR from "../common/label/labelWithHR";
import { createOrder } from "../actions/ticket";
import GenericMobileCard from "./general/genericMobileCard";

const orderMobile = () => {
  const { handleSubmit, control } = useForm();

  const [order, setOrder] = useState<OrderBill>({
    firstName: "",
    lastName: "",
    street: "",
    city: "",
    postalCode: "",
  });

  const history = useHistory();
  const { isLoading } = useSelector((state: RootState) => state.loader);
  const [, setError] = useState("");
  const [openOrderSlipInfoModal, setOpenOrderSlipInfoModal] = useState(false);
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openInfoModal = () => {
    setOpenOrderSlipInfoModal(true);
  };

  const orderPaymentSlip = async () => {
    dispatch(createOrder(order, ticket, setError, openInfoModal));
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <GenericMobileCard title={t("Order.Order a payment slip").toString()}>
      <form onSubmit={handleSubmit(orderPaymentSlip)}>
        <div className={Style.form__flex}>
          <div className={Style.columnDirectionFirstName}>
            <LabelWithHR label={t("Order.First name")} />
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="firstName"
                  onChange={(e) => {
                    setOrder({ ...order, firstName: e.target.value });
                  }}
                />
              )}
            />
          </div>
          <div className={Style.columnDirectionLastName}>
            <LabelWithHR label={t("Order.Last name")} />
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="lastName"
                  onChange={(e) => {
                    setOrder({ ...order, lastName: e.target.value });
                  }}
                />
              )}
            />
          </div>
        </div>
        <LabelWithHR label={t("Order.Street and house number")} />
        <Controller
          name="street"
          defaultValue=""
          control={control}
          render={() => (
            <input
              type={"text"}
              className={Style.input}
              name="street"
              onChange={(e) => {
                setOrder({ ...order, street: e.target.value });
              }}
            />
          )}
        />
        <LabelWithHR label={t("Order.Post code")} />
        <Controller
          name="postalCode"
          defaultValue=""
          control={control}
          render={() => (
            <input
              type={"text"}
              className={Style.input}
              name="postalCode"
              onChange={(e) => {
                setOrder({ ...order, postalCode: e.target.value });
              }}
            />
          )}
        />
        <>
          <LabelWithHR label={t("Order.City")} />
          <Controller
            name="city"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"text"}
                className={Style.input}
                name="city"
                onChange={(e) => {
                  setOrder({ ...order, city: e.target.value });
                }}
              />
            )}
          />
        </>
        <Button
          text={t("Order.Order")}
          disabled={
            !order.firstName ||
            !order.lastName ||
            !order.street ||
            !order.city ||
            !order.postalCode
          }
          classNameWrapper={Style.buttonMobile}
        />
      </form>
      {openOrderSlipInfoModal && (
        <InfoModal
          open={openOrderSlipInfoModal}
          onClose={setTimeout(() => {
            setOpenOrderSlipInfoModal(false);
            history.push({
              pathname: "/ticket-details",
            });
          }, 4000)}
          title={t("InfoModals.Invoice order title")}
          infoText={t("InfoModals.Invoice order text")}
        />
      )}
    </GenericMobileCard>
  );
};

export default orderMobile;
