import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Style from "../tickets.module.scss";
import LabelWithHR from "../../common/label/labelWithHR";
import Button from "../../common/button/button";
import Navbar from "../../reusable/navbar";
import HeadingLabelDesktop from "../../common/label/headingLabelDesktop";
export function TicketsDesktop({
  enterReferenceAndLicensePlate,
  setTicket,
  ticket,
  error,
  setError,
  shouldDisable,
  version,
}) {
  const { handleSubmit, control } = useForm();
  const { t } = useTranslation();
  return (
    <div className={Style.mainContainerDesktop}>
      <Navbar />
      <div className={Style.formDesktop}>
        <HeadingLabelDesktop />
        <p className={Style.formDesktop__paragraphStyle}>
          <span>{t("Tickets.Fill the fields below").toString()}</span>
        </p>
        <div className={Style.formDesktop__ticketForm}>
          <form onSubmit={handleSubmit(enterReferenceAndLicensePlate)}>
            <LabelWithHR label={t("Tickets.Reference")} />
            <Controller
              name="reference"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"number"}
                  placeholder="123456789"
                  className={Style.input}
                  name="reference"
                  defaultValue={ticket.number}
                  onChange={(e) => {
                    if (e.target.value.includes(" ")) {
                      return;
                    }

                    setTicket({ ...ticket, number: e.target.value });
                  }}
                />
              )}
            />
            <LabelWithHR label={t("Tickets.License plate")} />
            <Controller
              name="licensePlate"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  style={{
                    textTransform: "uppercase",
                  }}
                  className={Style.input}
                  placeholder="ZH123456"
                  name="licensePlate"
                  defaultValue={ticket.licensePlate}
                  onChange={(e) => {
                    if (e.target.value.includes(" ")) {
                      return;
                    }

                    setTicket({ ...ticket, licensePlate: e.target.value });
                  }}
                />
              )}
            />

            {error && (
              <div
                className={Style.errorMessage}
                onClick={() => {
                  setError("");
                }}
              >
                {error}
              </div>
            )}

            <Button
              classNameWrapper={Style.buttonDesktopLeft}
              text={t("Tickets.Further")}
              disabled={shouldDisable()}
            />
          </form>
        </div>
      </div>
      <div className={Style.versionBox}>VERSION:{version}</div>
    </div>
  );
}
