import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import Style from "./languageMenu.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../reducers";
import {changeTicketLanguage} from "../actions/ticket";

interface LanguageMenuProps {
  style?: CSSProperties | undefined;
  closeMenu: () => void;
  changeLanguageLabel: (lan: string) => void;
}

const LanguageMenu = (props: LanguageMenuProps) => {
  const { i18n } = useTranslation();
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const dispatch = useDispatch();

    const handleClick = (lang: string) => {
    i18n.changeLanguage(lang);
    if(["en","de","fr","it"].includes(lang) && ticket?.documentLanguage && ticket.documentLanguage?.toLowerCase() !== lang){
        if(lang!=="en"){
            dispatch(changeTicketLanguage(ticket.number,lang));
        }else{
            dispatch(changeTicketLanguage(ticket.number,"de"));

        }
    }
  };

  //TODO map fuction kroz jezike
  return (
    <div
      className={
        isMobile ? Style.languageMenuMobile : Style.languageMenuDesktop
      }
      style={props.style}
    >
      <div
        className={Style.language}
        onClick={() => {
          handleClick("de");
          props.closeMenu();
          props.changeLanguageLabel("DE");
          localStorage.setItem("beppoSelectedLanguage", "de");
        }}
      >
        DE
      </div>
      <div
        className={Style.language}
        onClick={() => {
          handleClick("en");
          props.closeMenu();
          props.changeLanguageLabel("EN");
          localStorage.setItem("beppoSelectedLanguage", "en");
        }}
      >
        EN
      </div>
      <div
        className={Style.language}
        onClick={() => {
          handleClick("fr");
          props.closeMenu();
          props.changeLanguageLabel("FR");
          localStorage.setItem("beppoSelectedLanguage", "fr");
        }}
      >
        FR
      </div>
        <div
            className={Style.language}
            onClick={() => {
                handleClick("it");
                props.closeMenu();
                props.changeLanguageLabel("IT");
                localStorage.setItem("beppoSelectedLanguage", "it");
            }}
        >
            IT
        </div>
    </div>
  );
};

export default LanguageMenu;
