import React from "react";
import Style from "./headingLabelDesktop.module.scss";
import { useTranslation } from "react-i18next";

const HeadingLabelDesktop = () => {
  const { t } = useTranslation();

  return (
    <div className={Style.headingLabelDesktopPart1}>
      <span className={Style.headingLabelDesktopPart1}>
        {t<string>("Tickets.Desktop-Heading text part1")}
      </span>
      <span className={Style.headingLabelDesktopPart2}>
        {t<string>("Tickets.Desktop-Heading text part2")}
      </span>
    </div>
  );
};

export default HeadingLabelDesktop;
