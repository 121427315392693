import { combineReducers } from "redux";
import ticket, { TicketState } from "./ticket";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loader, { LoaderState } from "./loader";

const persistConfig = {
  key: "root",
  storage,
  whiteList: [ticket],
};

const rootReducer = combineReducers({
  ticket,
  loader,
});

export interface RootState {
  ticket: TicketState;
  loader: LoaderState;
}

export default persistReducer(persistConfig, rootReducer);
