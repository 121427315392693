import {CountryCode} from "../reducers/ticket";
import {selectedLanguage} from "../i18next";

export const currencyCode = (countryCode: CountryCode) => {
    switch (countryCode) {
        case "DE":
            return "EUR";
        case "CH":
            return "CHF";
        default:
            return "";
    }
};

export const formatCurrency = (value: number, countryCode: CountryCode) => {
    const locale = `${selectedLanguage}-${countryCode}`;

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};