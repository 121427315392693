import axios from "axios";
import { Dispatch } from "redux";
import { Objection, OrderBill, Ticket, TicketLogin } from "../reducers/ticket";
import { APPEAL_CREATED, GET_TICKET, SET_LOADER } from "./types";
import { History } from "history";
import i18next from "../i18next";

export const createObjection =
  (
    objection: Objection,
    setError: (error: string) => void,
    history: History,
    onClose?: () => void
  ) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    await axios
      .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/tickets/appeal`,
        objection
      )
      .then((response) => {
        dispatch({
          type: APPEAL_CREATED,
          payload: response.data,
        });
        history.push({
          pathname: "/ticket-details",
        });
      })
      .catch((err) => {
        setError("Invalid ticket" + err);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
        onClose && onClose();
      });
  };

export const createOrder =
  (
    order: OrderBill,
    ticket: Ticket,
    setError: (error: string) => void,
    openInfoModal?: () => void,
    onClose?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    await axios
      .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/tickets/${ticket.number}/${ticket.licensePlate}/mail-request`,
        order
      )
      .catch((err) => {
        setError("Invalid ticket" + err);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
        onClose && onClose();
        openInfoModal && openInfoModal();
      });
  };

export const getTicket =
  (ticket: TicketLogin, setError: (error: string) => void, history: History) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    await axios
      .get(
        `${process.env.REACT_APP_SERVICE_URL}/api/tickets/${
          ticket.number
        }/${ticket.licensePlate.toUpperCase()}`
      )
      .then((response) => {
        dispatch({
          type: GET_TICKET,
          payload: response.data,
        });
        history.push({
          pathname: "/ticket-details",
        });
      })
      .catch((err) => {
        //TODO resi ovo oko gresaka
        if (err.response?.status === 404) {
          setError(i18next.t("translation:Tickets.actions.404"));
          return;
        }
        if (err.response?.status === 409) {
          setError(i18next.t("translation:Tickets.actions.409"));
          return;
        }
        setError(i18next.t("translation:Tickets.actions.500"));
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const payment =
  (
    ticket: Ticket,
    selectedLanguage: string,
    setTransaction: (id: string) => void,
    setError: (error: string) => void,
    setOpenPayment: (open: boolean) => void,
    showLightbox: (show: boolean) => void,
    amount?: string
  ) => async (dispatch: Dispatch<any>) => {
    let url = `${process.env.REACT_APP_SERVICE_URL}/api/tickets/${ticket.number}/${selectedLanguage}/payment`;

    if (amount) {
          url += `?amount=${amount}`;
    }

    dispatch({
      type: SET_LOADER,
      payload: true,
    });

    await axios
      .post(
        url
      )
      .then((response) => {
        setTransaction(response.data.transactionId);
        showLightbox(true);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setOpenPayment(false);
      });
  };

export const downloadSlip =
  (
    ticket: Ticket,
    selectedLanguage: string,
    setOpenDownloadSlipInfoModal: (open: boolean) => void
  ) =>
  async (dispatch: any) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    axios({
      url: `${process.env.REACT_APP_SERVICE_URL}/api/tickets/${ticket.number}/${ticket.licensePlate}/${selectedLanguage}/printout`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Rechnung.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOpenDownloadSlipInfoModal(true);
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };


export const changeTicketLanguage =
    (ticketNumber: number, lang: string) =>
        async (dispatch: any) => {
          await axios
              .post(
                  `${process.env.REACT_APP_SERVICE_URL}/api/report/editDocumentLanguage/${
                      ticketNumber
                  }/${lang.toLowerCase()}`
              )
              .then((response) => {
                dispatch({
                  type: GET_TICKET,
                  payload: response.data,
                })
              })
              .catch(() => {
                console.log("Error while changing ticket language");
              });
        };
