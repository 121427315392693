import React from "react";
import Style from "./evidencePhotos.module.scss";
import GenericMobileCard from "./general/genericMobileCard";
import { useTranslation } from "react-i18next";
import { Ticket } from "../reducers/ticket";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

const SocarPhoto = () => {
  const { t } = useTranslation();
  const ticket: Ticket = useSelector((state: RootState) => state.ticket.ticket);
  return (
    <GenericMobileCard title={t("TicketDetails.socarTitle").toString()}>
      <div className={Style.formMobile__imgContainer}>
        {ticket?.gasStationZone?.powerOfAttorney && (
          <img
            src={ticket?.gasStationZone?.powerOfAttorney}
            width={"100%"}
            alt="socarImage"
            style={{ alignSelf: "center" }}
          />
        )}
      </div>
    </GenericMobileCard>
  );
};

export default SocarPhoto;
