import React from "react";
import Style from "./labelWithHR.module.scss";
import { isMobile } from "react-device-detect";

interface LabelWithHRProps {
  label: string;
}

const LabelWithHR = (props: LabelWithHRProps) => {
  return (
    <div className={isMobile ? Style.labelsMobile : Style.labels}>
      {props.label}
      <hr className={isMobile ? Style.hrMobile : Style.hr} />
    </div>
  );
};

export default LabelWithHR;
