import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-responsive-modal/styles.css";
import Style from "./orderBillModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import "react-image-gallery/styles/css/image-gallery.css";
import Button from "../common/button/button";
import LoadingIndicator from "../reusable/loadingIndicator";
import { RootState } from "../reducers";
import LabelWithHR from "../common/label/labelWithHR";
import { OrderBill } from "../reducers/ticket";
import { createOrder } from "../actions/ticket";
import GenericBeppoModal from "./general/genericBeppoModal";

interface OrderProps {
  onClose: () => void;
  openInfoModal: () => void;
  open: boolean;
  title: string;
}

const OrderBillModal = (props: OrderProps) => {
  const { isLoading } = useSelector((state: RootState) => state.loader);
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch();

  const [order, setOrder] = useState<OrderBill>({
    firstName: "",
    lastName: "",
    street: "",
    city: "",
    postalCode: "",
  });
  const [, setError] = useState("");
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const { t } = useTranslation();

  const orderPaymentSlip = async () => {
    dispatch(
      createOrder(order, ticket, setError, props.openInfoModal, props.onClose)
    );
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <GenericBeppoModal
      title={props.title}
      open={props.open}
      onClose={props.onClose}
    >
      <form onSubmit={handleSubmit(orderPaymentSlip)}>
        <div className={Style.formContainer__row}>
          <div className={Style.formContainer__flexOne__margin}>
            <LabelWithHR label={t("Order.First name")} />
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="firstName"
                  onChange={(e) => {
                    setOrder({ ...order, firstName: e.target.value });
                  }}
                />
              )}
            />
          </div>
          <div className={Style.formContainer__flexOne}>
            <LabelWithHR label={t("Order.Last name")} />
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              render={() => (
                <input
                  type={"text"}
                  className={Style.input}
                  name="lastName"
                  onChange={(e) => {
                    setOrder({ ...order, lastName: e.target.value });
                  }}
                />
              )}
            />
          </div>
        </div>
        <div>
          <LabelWithHR label={t("Order.Street and house number")} />
          <Controller
            name="street"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"text"}
                className={Style.input}
                name="street"
                onChange={(e) => {
                  setOrder({ ...order, street: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div>
          <LabelWithHR label={t("Order.Post code")} />
          <Controller
            name="postalCode"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"text"}
                className={Style.input}
                name="postalCode"
                onChange={(e) => {
                  setOrder({ ...order, postalCode: e.target.value });
                }}
              />
            )}
          />
        </div>
        <div>
          <LabelWithHR label={t("Order.City")} />
          <Controller
            name="city"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"text"}
                className={Style.input}
                name="city"
                onChange={(e) => {
                  setOrder({ ...order, city: e.target.value });
                }}
              />
            )}
          />
        </div>
        <Button
          text={t("Order.Order")}
          disabled={
            !order.firstName ||
            !order.lastName ||
            !order.street ||
            !order.city ||
            !order.postalCode
          }
          classNameWrapper={Style.buttonDesktop}
        />
      </form>
    </GenericBeppoModal>
  );
};

export default OrderBillModal;
