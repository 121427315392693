import React from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { getModalStyle } from "../../reusable/functions";
import Style from "./ModalConfirmPasswordReset.module.css";
import { isMobile } from "react-device-detect";

interface ModalConfirmPasswordResetProps {
  open: boolean;
  close: () => void;
}

const ModalConfirmPasswordReset = (props: ModalConfirmPasswordResetProps) => {
  return (
    <Modal
      open={props.open}
      closeOnEsc={true}
      showCloseIcon={false}
      onClose={props.close}
      center
      styles={getModalStyle()}
    >
      <div className={Style.textContainer}>
        <div
          className={
            isMobile
              ? Style.textContainer__flexMobile
              : Style.textContainer__flex
          }
        >
          <div>
            <span className={Style.textContainer__text}>
              Passwort erfolgreich aktualisiert.
            </span>
          </div>
        </div>
      </div>
      <div className={Style.buttonContainer}>
        <div className={Style.buttonContainer__buttonPosition}>
          <button
            className={Style.buttonContainer__buttonStyle}
            onClick={() => {
              window.location.reload();
            }}
          >
            Zurück
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ModalConfirmPasswordReset;
