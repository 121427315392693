import React from "react";
import Style from "./inputFieldWithLabel.module.scss";
import { isMobile } from "react-device-detect";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface InputFieldWithLabelProps {
  label: string;
  style: any;
  type: string;
  register: UseFormRegister<FieldValues>;
  registerName: string;
  required: boolean;
  name: string;
  defaultValue?: string;
  placeholder?: string;
}

const InputFieldWithLabel = (props: InputFieldWithLabelProps) => {
  return (
    <div className="form-group" style={props.style}>
      <div className={isMobile ? Style.labelsMobile : Style.labels}>
        {props.label}
        <hr className={Style.hr} />
      </div>
      <div>
        <input
          type={props.type ? props.type : "text"}
          className={Style.input}
          {...props.register(props.registerName)}
          required={props.required}
          name={props.name}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
};

export default InputFieldWithLabel;
