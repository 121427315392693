import React, {useContext} from "react";
import "react-responsive-modal/styles.css";
import Style from "./bankTransferModal.module.scss";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import QRCode from "react-qr-code";
import {
    businessName,
    country,
    iban,
    postalCode,
    streetName,
    streetNumber,
    townName,
    bic,
    bank,
    ibanText,
    ibanSocarText,
    ibanSocar,
} from "../reusable/functions";
import QRLogo from "../common/assets/qrLogo.png";
import {RootState} from "../reducers";
import GenericBeppoModal from "./general/genericBeppoModal";
import {TicketDetailsContext} from "./TicketDetailsContext";

interface BankTransferProps {
    onClose: () => void,
    open: boolean,
    title: string,
    totalAmount: string,
    currency?: string
}

const BankTransferModal = (props: BankTransferProps) => {
    const ticket = useSelector((state: RootState) => state.ticket.ticket);
    const {t} = useTranslation();

    const {formattedAmountLabel}
        = useContext(TicketDetailsContext);

    const ibanInQR = ticket.gasStationZone != null ? ibanSocar : iban;

    const qrValue =
        "SPC\n" +
        "0200\n" +
        "1\n" +
        ibanInQR +
        "\n" +
        "S\n" +
        businessName +
        "\n" +
        streetName +
        "\n" +
        streetNumber +
        "\n" +
        postalCode +
        "\n" +
        townName +
        "\n" +
        country +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        props.totalAmount +
        "\n" +
        "CHF\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "NON\n" +
        "\n" +
        ticket.number +
        "\n" +
        "EPD\n";

  let totalAmount = props.totalAmount;
  if (props.currency) {
    totalAmount = totalAmount + " " + props.currency;
  }
  return (
        <GenericBeppoModal
            title={props.title}
            open={props.open}
            onClose={props.onClose}
        >
            <div className={Style.qrContainer}>
                <div className={Style.qrContainer__title}>
                    {t("TicketDetails.QR title").toString()}
                </div>
                <div className={Style.qrContainer__box}>
                    <img src={QRLogo} alt="qrLogo" className={Style.qrIcon}/>
                    <QRCode value={qrValue} size={150}/>
                </div>
            </div>
            <div className={Style.detailsContainer}>
                <div className={Style.detailsContainer__heading}>
                    {t("TicketDetails.Account details").toString()}
                </div>
                <div>
                    <div className={Style.detailsContainer__paragraphDesktop}>
                        <span>{t("TicketDetails.Account owner").toString()}</span>
                        <div className={Style.detailsContainer__horizontalLine}></div>
                        <span className={Style.detailsContainer__paragraphBold}>
              {businessName}
            </span>
                    </div>
                    <div className={Style.detailsContainer__paragraphDesktop}>
                        <span>IBAN</span>
                        <div className={Style.detailsContainer__horizontalLine}></div>
                        <span className={Style.detailsContainer__paragraphBold}>
              {ticket.gasStationZone != null ? ibanSocarText : ibanText}
            </span>
                    </div>
                    <div className={Style.detailsContainer__paragraphDesktop}>
                        <span>BIC</span>
                        <div className={Style.detailsContainer__horizontalLine}></div>
                        <span className={Style.detailsContainer__paragraphBold}>{bic}</span>
                    </div>
                    <div className={Style.detailsContainer__paragraphDesktop}>
                        <span>{t("TicketDetails.Financial institution").toString()}</span>
                        <div className={Style.detailsContainer__horizontalLine}></div>
                        <span className={Style.detailsContainer__paragraphBold}>
              {bank}
            </span>
                    </div>
                    <div className={Style.detailsContainer__paragraphDesktop}>
                        <span>{formattedAmountLabel}</span>
                        <div className={Style.detailsContainer__horizontalLine}></div>
                        <span className={Style.detailsContainer__paragraphBold}>
              {totalAmount}
            </span>
                    </div>
                    <div className={Style.detailsContainer__paragraphDesktop}>
            <span className={Style.detailsContainer__infoTextStyle}>
              {t("TicketDetails.Additional information").toString()}
            </span>
                        <div className={Style.detailsContainer__horizontalLine}></div>
                        <span
                            className={Style.detailsContainer__paragraphBold}
                            style={{color: "#CC0605"}}
                        >
              {ticket.number}
            </span>
                    </div>
                </div>
            </div>
        </GenericBeppoModal>
    );
};

export default BankTransferModal;
