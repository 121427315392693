import React from "react";
import "react-responsive-modal/styles.css";
import GenericBeppoModal from "./general/genericBeppoModal";
import { Ticket } from "../reducers/ticket";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

const SocarPhoto = (props: any) => {
  const ticket: Ticket = useSelector((state: RootState) => state.ticket.ticket);
  return (
    <GenericBeppoModal
      title={props.title}
      open={props.open}
      onClose={props.onClose}
    >
      {ticket?.gasStationZone?.powerOfAttorney && (
        <img
          src={ticket?.gasStationZone?.powerOfAttorney}
          width={"100%"}
          alt="socarImage"
          style={{ alignSelf: "center" }}
        />
      )}
    </GenericBeppoModal>
  );
};

export default SocarPhoto;
