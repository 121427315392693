import React from "react";
import "react-responsive-modal/styles.css";
import GenericBeppoModal from "./general/genericBeppoModal";
import Style from "./parkingZoneTextModal.module.scss";

interface ParkingZoneTextProps {
  value: string;
  title: string;
  onClose: () => void;
  open: boolean;
}

const ParkingZoneTextModal = (props: ParkingZoneTextProps) => {
  return (
    <GenericBeppoModal
      title={props.title}
      open={props.open}
      onClose={props.onClose}
    >
      <div
        dangerouslySetInnerHTML={{ __html: props.value }}
        className={Style.pzTextContainer}
      ></div>
    </GenericBeppoModal>
  );
};

export default ParkingZoneTextModal;
