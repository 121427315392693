import React from "react";
import Style from "./paymentButton.module.scss";

interface PaymentButtonProps {
  desktop: boolean;
  disabled: boolean;
  buttonStyle?: any;
  onClick: () => void;
  text: string;
  buttonContentStyle?: any;
  imgSrc?: string;
  imgAlt?: string;
  imgStyle?: any;
}

const PaymentButton = (props: PaymentButtonProps) => {
  return (
    <div
      className={props.desktop ? Style.pbuttonDesktop : Style.pbuttonMobile}
    >
      <button
        type="button"
        className={props.disabled ? Style.buttonDisabled : Style.button}
        style={props.buttonStyle}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <div
          className={
            props.desktop
              ? Style.contentContainerDesktop
              : Style.contentContainerMobile
          }
          style={props.buttonContentStyle}
        >
          <img src={props.imgSrc} alt={props.imgAlt} style={props.imgStyle} />
          <span
            className={
              props.desktop ? Style.textSpanDesktop : Style.textSpanMobile
            }
          >
            {props.text}
          </span>
        </div>
      </button>
    </div>
  );
};

PaymentButton.defaultProps = {
  desktop: true,
  disabled: false,
};

export default PaymentButton;
