import React from "react";
import Loader from "react-loader-spinner";
import Style from "./loadingIndicator.module.scss";

const LoadingIndicator = () => {
  return (
    <div className={Style.loadingIndicator}>
      <Loader
        type="Oval"
        color="#00BFFF"
        height="500"
        width="100"
        justify-content="center"
        align-items="center"
      />
    </div>
  );
};

export default LoadingIndicator;
