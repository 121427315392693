import React, {useContext} from "react";
import Lightbox from "react-datatrans-light-box";
import {TicketDetailsContext} from "../components/TicketDetailsContext";

const BeppoLightBox = () => {
  const {
    isProduction,
    onLoaded,
    onOpened,
    onCancelled,
    onError,
    transaction,
    lightbox
  } = useContext(TicketDetailsContext)

  if (!lightbox || !transaction) {
    return null;
  }
  return (
    <Lightbox
      transactionId={transaction}
      production={isProduction}
      onLoaded={onLoaded}
      onOpened={onOpened}
      onCancelled={onCancelled}
      onError={onError}
    />
  );
};

export default BeppoLightBox;
