import React from "react";
import MobileHeader from "../../reusable/mobileHeader";
import Style from "./genericMobileCard.module.scss";

interface GenericMobileCardProps {
  children: React.ReactNode;
  title: string;
}

const GenericMobileCard = (props: GenericMobileCardProps) => {
  return (
    <div className={Style.mainContainer}>
      <MobileHeader arrowVisible />
      <div className={Style.headingContainer}>
        <div className={Style.hairline}></div>
        <div className={Style.mobileTitle}>{props.title}</div>
      </div>
      <div className={Style.form}>{props.children}</div>
    </div>
  );
};

export default GenericMobileCard;
