import React, {useContext, useState} from "react";
import Navbar from "../reusable/navbar";
import PaymentButton from "../common/button/paymentButton";
import CreditCardWhite from "../common/assets/credit-cardWhite.svg";
import MoneyIcon from "../common/assets/money-icon.svg";
import Style from "./ticketsDetails.module.scss";
import {useTranslation} from "react-i18next";
import EvidencePhotosModal from "./evidencePhotosModal";
import ObjectionModal from "./objectionModal";
import InfoModal from "./infoModal";
import BankTransferModal from "./bankTransferModal";
import {convertStringToCountryCode, TicketStatus} from "../reducers/ticket";
import SocarPhoto from "./socarPhotoModal";
import {TicketDetailsContext} from "./TicketDetailsContext";
import {currencyCode} from "../util/formatter";

const FINAL_STATUSES: string[] = [TicketStatus.PAID, TicketStatus.STOPPED];

const TicketDetailsGasStationDesktop = () => {
  const {
    ticket,
    setOpenPayment,
    setPendingAmount,
    datatransPaymentSuccessffullModalOpen,
    setDatatransPaymentSuccessffullModalOpen,
    datatransPaymentErrorModalOpen,
    setDatatransPaymentErrorModalOpen,
    formattedAmountLabel
  } = useContext(TicketDetailsContext);

  const { t } = useTranslation();
  const [openSocarPhoto, setOpenSocarPhoto] = useState(false);
  const [openEvidencePhotosModal, setOpenEvidencePhotosModal] = useState(false);
  const [openObjectionModal, setOpenObjectionModal] = useState(false);
  const [openOrderSlipInfoModal, setOpenOrderSlipInfoModal] = useState(false);
  const [openBankTransferModal, setOpenBankTransferModal] = useState(false);
  const [amount, setAmount] = useState("0,00");

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value.replace(",", "."));
    if (!isNaN(value)) {
      setAmount(value.toFixed(2).replace(".", ","));
    } else {
      setAmount("0,00");
    }
  };

  if (!ticket) {
    return null;
  }

  const ticketSeriesCountryCode = ticket?.ticketSeriesCountryCode;
  const countryCode = convertStringToCountryCode(ticketSeriesCountryCode);

  let currency: string;
  if (countryCode) {
    currency = currencyCode(countryCode);
  }
  else {
    currency = "";
  }

  const renderContent = () => {
    return (
      <div className={Style.mainContainerDesktop}>
        <Navbar />
        <div className={Style.formDesktop}>
          <h3 className={Style.formDesktop__subtitle}>
            {t<string>("TicketDetails.PaymentOfFuelBill")}
          </h3>
          <div className={Style.formDesktop__scrollableDiv}>
            <div className={Style.formDesktop__detailsAndImgContainer}>
              <div className={Style.formDesktop__detailsContainer}>
                <div className={Style.detailsContainer}>
                  <div>
                    <span className={Style.formDesktop__paragraph}>
                      {t<string>("TicketDetails.ReferenceNo")}
                    </span>
                    <span className={Style.detailsContainer__boldValues} style={{ marginLeft: "8px" }}>
                      {ticket.number}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <p></p>

            <div className={Style.formDesktop__paragraph}>
              {t<string>("TicketDetails.pendingGSTicketText")}
            </div>
            <p></p>
            <div className={Style.formDesktop__inputContainer} style={{ display: "flex", alignItems: "center" }}>
              <label htmlFor="amount" className={Style.formDesktop__inputLabel}>
                {formattedAmountLabel}
              </label>
              <input
              style={{textAlign: "right", marginLeft: "8px"}}
                type="text"
                id="amount"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onBlur={handleBlur}
                onFocus={(e) => {
                    if (e.target.value === "0,00") {
                      setAmount("");
                    }
                  }}
                className={Style.formDesktop__inputField}
              />
              <div className={Style.formDesktop__inputCurrency}>{currency}</div>
            </div>

          </div>
            {!FINAL_STATUSES.includes(ticket.status) && (
            <>
              <div>

                  <div className={Style.buttonContainer}>
                  <PaymentButton
                      text={t("TicketDetails.BankTransfer")}
                      desktop
                      imgSrc={MoneyIcon}
                      imgAlt="bankTransferIcon"
                      onClick={() => {
                        setOpenBankTransferModal(true);
                      }}
                    />

                    <div style={{ flex: 0.1 }}></div>
                    <PaymentButton
                      text={t("TicketDetails.Online payment")}
                      desktop
                      imgSrc={CreditCardWhite}
                      imgAlt="cardWhite"
                      onClick={() => {
                        setPendingAmount(amount);
                        setOpenPayment(true);
                      }}
                    />
                  </div>
              </div>
            </>
          )}
        </div>


        {openSocarPhoto && (
          <SocarPhoto
            open={openSocarPhoto}
            title={t("TicketDetails.socarTitle")}
            onClose={() => setOpenSocarPhoto(false)}
          />
        )}
        {openEvidencePhotosModal && (
          <EvidencePhotosModal
            open={openEvidencePhotosModal}
            onClose={() => setOpenEvidencePhotosModal(false)}
            title={t("TicketDetails.Photos")}
          />
        )}
        {openObjectionModal && (
          <ObjectionModal
            open={openObjectionModal}
            onClose={() => setOpenObjectionModal(false)}
            title={t("TicketDetails.Objection")}
            gasStationZone={ticket.gasStationZone}
          />
        )}

        {openOrderSlipInfoModal && (
          <InfoModal
            open={openOrderSlipInfoModal}
            onClose={setTimeout(() => {
              setOpenOrderSlipInfoModal(false);
            }, 4000)}
            title={t("InfoModals.Invoice order title")}
            infoText={t("InfoModals.Invoice order text")}
          />
        )}
        {openBankTransferModal && (
          <BankTransferModal
            open={openBankTransferModal}
            onClose={() => setOpenBankTransferModal(false)}
            title={t("TicketDetails.BankTransfer")}
            totalAmount={amount}
            currency={currency}
          />
        )}

        {datatransPaymentSuccessffullModalOpen && (
          <InfoModal
            open={datatransPaymentSuccessffullModalOpen}
            onClose={setTimeout(() => {
              setDatatransPaymentSuccessffullModalOpen(false);
              window.location.replace("/ticket-details");
            }, 4000)}
            title={t("InfoModals.Online payment successfull gas station title")}
            infoText={t(
              "InfoModals.Online payment successfull gas station text"
            )}
          />
        )}

        {datatransPaymentErrorModalOpen && (
          <InfoModal
            open={datatransPaymentErrorModalOpen}
            onClose={setTimeout(() => {
              setDatatransPaymentErrorModalOpen(false);
              window.location.replace("/ticket-details");
            }, 4000)}
            title={t("InfoModals.Online payment error title")}
            infoText={t("InfoModals.Online payment error text")}
          />
        )}
      </div>
    );
  };

  return renderContent();
};

export default TicketDetailsGasStationDesktop;
