import React from "react";
import { useForm, Controller } from "react-hook-form";
import MobileHeader from "../../reusable/mobileHeader";
import Style from "../tickets.module.scss";
import LabelWithHR from "../../common/label/labelWithHR";
import Button from "../../common/button/button";
import { useTranslation } from "react-i18next";
export function TicketsMobile({
  enterReferenceAndLicensePlate,
  setTicket,
  ticket,
  error,
  setError,
  shouldDisable,
  version,
}) {
  const { handleSubmit, control } = useForm();
  const { t } = useTranslation();
  return (
    <div className={Style.mainContainerMobile}>
      <MobileHeader logoHeader />
      <div className={Style.headingContainer}>
        <div className={Style.hairline}></div>
        <div className={Style.headingContainer__title}>
          <span className={Style.headingLabelDesktopPart1}>
            {t("Tickets.Desktop-Heading text part1").toString()}
          </span>
          <span className={Style.headingLabelDesktopPart2}>
            {t("Tickets.Desktop-Heading text part2").toString()}
          </span>
        </div>
      </div>
      <div className={Style.formMobile}>
        <p className={Style.formMobile__paragraphStyle}>
          {t("Tickets.Fill the fields below").toString()}
        </p>
        <form onSubmit={handleSubmit(enterReferenceAndLicensePlate)}>
          <LabelWithHR label={t("Tickets.Reference")} />
          <Controller
            name="reference"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"number"}
                placeholder="123456789"
                className={Style.input}
                name="reference"
                value={ticket.number}
                onChange={(e) => {
                  if (e.target.value.includes(" ")) {
                    return;
                  }

                  setTicket({ ...ticket, number: e.target.value });
                }}
              />
            )}
          />

          <LabelWithHR label={t("Tickets.License plate")} />
          <Controller
            name="licensePlate"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"text"}
                placeholder="ZH123456"
                className={Style.input}
                name="licensePlate"
                style={{
                  textTransform: "uppercase",
                }}
                value={ticket.licensePlate}
                onChange={(e) => {
                  if (e.target.value.includes(" ")) {
                    return;
                  }

                  setTicket({ ...ticket, licensePlate: e.target.value });
                }}
              />
            )}
          />

          {error && (
            <div
              className={Style.errorMessage}
              onClick={() => {
                setError("");
              }}
            >
              {error}
            </div>
          )}
          <Button
            text={t("Tickets.Further")}
            disabled={shouldDisable()}
            classNameWrapper={Style.buttonMobile}
          />
        </form>
      </div>
      <div className={Style.versionBox}>VERSION:{version}</div>
    </div>
  );
}
