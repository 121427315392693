import { SET_LOADER } from "../actions/types";

const initialState = {
  isLoading: false,
};

export interface LoaderState {
  isLoading: boolean;
}

const loaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default loaderReducer;
