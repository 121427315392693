import React from "react";
import { useSelector } from "react-redux";
import Style from "./pzTextPageMobile.module.scss";
import { useTranslation } from "react-i18next";
import { RootState } from "../reducers";
import GenericMobileCard from "./general/genericMobileCard";

const PZTextPageMobile = () => {
  const ticket = useSelector((state: RootState) => state.ticket.ticket);
  const { t } = useTranslation();

  return (
    <GenericMobileCard title={t("TicketDetails.parkingZoneText").toString()}>
      <div
        className={Style.pzTextContainer}
        dangerouslySetInnerHTML={{
          __html: ticket.parkingZone.parkingZoneText,
        }}
      ></div>
    </GenericMobileCard>
  );
};

export default PZTextPageMobile;
